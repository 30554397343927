import AsyncStorage from "@react-native-async-storage/async-storage"
import { auth, functions } from "@siruplab/capsule"

import { Perso } from "../features/models/UserData"
import { forcePerso } from "../features/models/UserDataFunctions"
import { deleteSearchParams } from "./functions"
import isWeb from "./isWeb"

export const getPersoCondition = (perso: boolean) =>
  perso === true ? Perso.unlocked : perso === false ? Perso.editLock : undefined

export const signIn = async (customToken: string) => {
  await auth().signInWithCustomToken(customToken)

  const token = await auth().currentUser?.getIdTokenResult()

  // Only on mobile (On web we have session = none)
  if (!isWeb) {
    AsyncStorage.setItem("forcePerso", JSON.stringify(token?.claims?.forcePerso))
  }

  // Case forcePerso = true (Mean => The Admin is connected and can't edit params but can edit cards, hierarchy etc...) ==> Perso.unlocked
  // Case forcePerso = false (Mean => An anonymous user is connected and can't edit)  ==> false
  // Case forcePerso don't exist (Mean => The REAL USER IS CONNECTED) ==> null

  forcePerso.current = getPersoCondition(token?.claims?.forcePerso)
}

export const handleTokens = async (url: URL): Promise<string> => {
  const customToken = url.searchParams.get("customToken")
  const refreshToken = url.searchParams.get("refreshToken")
  const currentUser = auth().currentUser

  if (currentUser) {
    const forcePersoFromStorage = await AsyncStorage.getItem("forcePerso")
    if (forcePersoFromStorage != null) {
      forcePerso.current = getPersoCondition(JSON.parse(forcePersoFromStorage))
    }
  }

  if (customToken) {
    await signIn(customToken)
  }

  if (refreshToken) {
    const newToken = await functions().httpsCallable("refreshToken")(refreshToken)
    await signIn(newToken.data)
  }

  deleteSearchParams(url, ["refreshToken", "customToken"])

  return url.href
}
